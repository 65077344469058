import styled from "styled-components";

export const Layout = styled.div`
  width: 100%;
  font-size: 1rem;
  min-height: 100%;
  margin: 0 auto;
  padding: ${(p) => p.theme.padding.outer};
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  overflow: hidden;
  pointer-events: none;

  * {
    pointer-events: auto;
  }
`;

export const LayoutContent = styled.div`
  min-height: 100%;
  flex: 1;
  width: 100%;
  max-width: ${(p) => p.theme.maxWidth};
  pointer-events: none;
  display: flex;
  flex-direction: column;

  * {
    pointer-events: auto;
  }
`;

import Modal from "./Modal";
import { useInterfaceStore } from "../stores/useInterfaceStore";
import { useUserStore } from "../stores/useUserStore";
import Button from "./Button";
import Input from "./Input";
import { useState } from "react";
import { signUp } from "../services/auth/signUp";
import { useCookie } from "../composables/useCookie";
import styled from "styled-components";
import Text from "./copys/Text";

export default () => {
  const visible = useInterfaceStore((state) => state.signUpVisible);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useCookie("token");

  const onClose = () => {
    useInterfaceStore.setState({ signUpVisible: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    const formData = new FormData(e.target);
    const name = formData.get("name");
    setIsLoading(true);
    const data = await signUp({ name });
    setIsLoading(false);

    if (data.error) return setError(data.error);

    setToken(data.token, { expires: 365, secure: window.location.protocol === "https:", sameSite: "Lax" });
    useInterfaceStore.setState({ signUpVisible: false });
    useUserStore.setState(data);
  };

  return (
    <Modal title="My nickname is..." visible={visible} onClose={onClose}>
      <Form onSubmit={handleSubmit}>
        <Text color="500">Pick a nickname. It is shown to other players, so choose a nice one!</Text>
        <Content>
          <NameInput autoFocus type="text" name="name" />
        </Content>
        <Button type="submit" loading={isLoading}>
          Sign Up
        </Button>
      </Form>
    </Modal>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Content = styled.div``;

const NameInput = styled(Input)`
  margin-bottom: 0.5rem;
  width: 100%;
`;

import styled from "styled-components";

const getFontSize = (size = "md") => {
  switch (size) {
    case "md":
      return "1rem";
    case "sm":
      return "0.75rem";
  }
};

const getLineHeight = (size = "md") => {
  switch (size) {
    case "md":
      return "1.25rem";
    case "sm":
      return "1rem";
  }
};

export default styled.p`
  line-height: ${(p) => getLineHeight(p.size)};
  font-size: ${(p) => getFontSize(p.size)};
  color: ${(p) => p.theme.text[p.color || 400]};
  font-weight: 500;

  strong {
    font-weight: 700;
  }
`;

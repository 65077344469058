import { useState, useEffect } from "react";
import { useConfig } from "vike-react/useConfig";

export const useTouch = () => {
  const config = useConfig();
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    const checkTouch = () => {
      const touchDetected = "ontouchstart" in document.documentElement;
      setIsTouch(touchDetected);
      document.body.classList.add(touchDetected ? "touch" : "no-touch");
    };

    checkTouch();
  }, []);

  return { isTouch };
};

import { CSSTransition } from "react-transition-group";
import { useRef } from "react";
import IconCross from "./icons/Cross";
import ButtonIcon from "./ButtonIcon";
import styled from "styled-components";
import Headline from "./copys/Headline";

const TRANSITION = {
  NAME: "modal",
  ENTER: 0.125,
  EXIT: 0.125,
};

export default ({ children, title, onClose = () => {}, visible }) => {
  const modalRef = useRef(null);

  return (
    <CSSTransition
      in={visible}
      timeout={{ enter: TRANSITION.ENTER * 1000, exit: TRANSITION.EXIT * 1000 }}
      classNames={TRANSITION.NAME}
      unmountOnExit
      nodeRef={modalRef}>
      <Background ref={modalRef} onClick={onClose}>
        <Wrapper onClick={(e) => e.stopPropagation()}>
          <Header>
            <Headline size="xs">{title}</Headline>
            <CloseButton>
              <IconCross onClick={onClose} />
            </CloseButton>
          </Header>
          <Content>{children}</Content>
        </Wrapper>
      </Background>
    </CSSTransition>
  );
};

const HEADER_HEIGHT = "3rem";

const Wrapper = styled.div`
  background: ${(p) => p.theme.background};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;

  &.${TRANSITION.NAME}-enter {
    opacity: 0;

    & ${Wrapper} {
      transform: translateY(5rem);
    }
  }

  &.${TRANSITION.NAME}-enter-active {
    opacity: 1;
    transition: opacity ${TRANSITION.ENTER}s;

    & ${Wrapper} {
      transition: transform ${TRANSITION.ENTER}s ease-out;
      transform: translateY(0);
    }
  }

  &.${TRANSITION.NAME}-exit {
    opacity: 1;

    & ${Wrapper} {
      transform: scale(1);
    }
  }

  &.${TRANSITION.NAME}-exit-active {
    opacity: 0;
    transition: opacity ${TRANSITION.EXIT}s;

    & ${Wrapper} {
      transition: transform ${TRANSITION.EXIT}s ease-out;
      transform: translateY(5rem);
    }
  }
`;

const Header = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-bottom: ${(p) => p.theme.stroke[400]} solid ${(p) => p.theme.grayscale[200]};
  height: ${HEADER_HEIGHT};
  color: ${(p) => p.theme.text[500]};
  width: 100%;
`;

const Content = styled.div`
  padding: ${(p) => p.theme.padding.outer};
  min-height: 100%;
  flex: 1;
  max-width: ${(p) => p.theme.maxWidth};
  width: 100%;
`;

const CloseButton = styled(ButtonIcon)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

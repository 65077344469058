import { TextureLoader } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import EventEmitter from "../../libraries/EventEmitter";

const sources = [
  //TBD: make noise required for all games
  //{ name: "noise", type: "texture", path: "/assets/textures/noise.png", required: true },
  { name: "colorMap", type: "texture", path: "/assets/textures/colorMap.png", required: true },
  { name: "avatar", type: "gltfModel", path: "/assets/models/avatar.glb", required: true },
];

class Resources extends EventEmitter {
  constructor() {
    super();

    this.items = {};
    this.loaded = 0;
    this.toLoad = 0;

    this.setLoaders();
  }

  setLoaders() {
    this.loaders = {};
    this.loaders.gltfLoader = new GLTFLoader();
    this.loaders.textureLoader = new TextureLoader();
  }

  async startLoading() {
    this.loaded = 0;
    const requiredSources = sources.filter((source) => source.required);
    this.toLoad = requiredSources.length;

    if (this.toLoad === 0) {
      this.isReady = true;
      this.emit("ready");
      return;
    }

    for (let i = 0; i < requiredSources.length; i++) {
      this.loadSource(requiredSources[i]);
    }
  }

  loadSource(source) {
    if (this.items[source.name]) return Promise.resolve(this.items[source.name]);
    return new Promise(async (resolve) => {
      //const path = `${source.path}?v=${process.env.BUILDID}`;
      const { path } = source;
      if (source.type === "gltfModel") {
        this.loaders.gltfLoader.load(path, (file) => {
          this.sourceLoaded(source, file);
          return resolve(file);
        });
      } else if (source.type === "texture") {
        this.loaders.textureLoader.load(path, (file) => {
          this.sourceLoaded(source, file);
          file.flipY = false;
          return resolve(file);
        });
      } else if (source.type === "image") {
        const image = new Image();
        image.src = path;
        image.onload = () => {
          this.sourceLoaded(source, image);
          return resolve(image);
        };
        image.onerror = () => {
          console.error(`Error loading image ${path}`);
          this.emit("error");
          return resolve();
        };
      }
    });
  }

  sourceLoaded(source, file) {
    this.items[source.name] = file;

    this.loaded = Object.keys(this.items).length;
    this.emit("progress", this.loaded / this.toLoad);
    if (!this.isReady && this.loaded === this.toLoad) {
      this.isReady = true;
      this.emit("ready");
    }
  }

  async loadGameSources(slug, sources) {
    const sanitizedSources = sources.map((source) => ({
      ...source,
      name: `${slug}-${source.name}`,
      path: `/assets/games/${slug}${source.path}`,
    }));
    return Promise.all(sanitizedSources.map((source) => this.loadSource(source)));
  }

  getGameSource(slug, name) {
    return this.items[`${slug}-${name}`];
  }
}

export default new Resources();

import { getGameSlugFromUrl } from "../../utils/common";
import { extractLocale } from "./extractLocale";

const gameTranslationFiles = import.meta.glob("~~/games/**/lang/*.json");
const pageTranslationFiles = import.meta.glob("~/lang/pages/**/*.json");

const metaTags = ["title", "twitter_title", "og_title", "description", "keywords"];

//caches
const commonTranslations = {};
const routeTranslations = {};

export const translations = {};

export const loadCommonTranslations = async (locale) => {
  if (Object.keys(commonTranslations[locale] || {}).length) return commonTranslations[locale];
  if (!locale) return;
  const locales = await import(`../common/${locale}.json`);

  if (translations[locale]) {
    translations[locale] = { ...translations[locale], ...locales.default };
  } else {
    translations[locale] = locales.default;
  }

  commonTranslations[locale] = locales.default;

  return locales.default;
};

export const getRouteTranslations = async (route, locale) => {
  const gameSlug = getGameSlugFromUrl(route);
  let cacheRoute = `/${locale}${route}`;
  if (gameSlug) cacheRoute = `/${locale}/${gameSlug}`;

  if (Object.keys(routeTranslations[cacheRoute] || {}).length) return routeTranslations[cacheRoute];

  const translationFiles = gameSlug ? gameTranslationFiles : pageTranslationFiles;
  const routeTranslationPath = gameSlug
    ? `../games/${gameSlug}/lang/${locale}.json`
    : `/lang/pages${route === "/" ? "/index" : route}/${locale}.json`;

  let tr = {};

  try {
    if (translationFiles[routeTranslationPath]) {
      const imported = await translationFiles[routeTranslationPath]();
      tr = imported.default;
    } else {
      console.log("No meta file for", routeTranslationPath);
      tr = { title: "Gamly" };
    }
  } catch (e) {
    console.log(e);
    console.log("Error loading meta file:", routeTranslationPath);
  }

  if (gameSlug) {
    //prefix all keys with game slug, except for meta tags
    const prefixedTranslations = {};
    Object.keys(tr).forEach((key) => {
      if (metaTags.includes(key)) {
        prefixedTranslations[key] = tr[key];
      } else {
        prefixedTranslations[`${gameSlug}-${key}`] = tr[key];
      }
    });
    tr = prefixedTranslations;
  }

  routeTranslations[cacheRoute] = tr;
  console.log("loaded", cacheRoute);

  return tr;
};

export const loadRouteTranslations = async (route) => {
  const { urlPathnameWithoutLocale, locale } = extractLocale(route);
  const t = await getRouteTranslations(urlPathnameWithoutLocale, locale);
  const common = await loadCommonTranslations(locale);

  if (translations[locale]) {
    translations[locale] = { ...translations[locale], ...common, ...t };
  }

  return { ...common, ...t };
};

import { forwardRef, memo } from "react";

const SvgComponent = (props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="none" ref={ref} {...props}>
    <path
      stroke="var(--stroke-color)"
      strokeWidth="var(--stroke-width)"
      vectorEffect="non-scaling-stroke"
      d="M216.383 39.6066C206.62 29.8435 190.791 29.8435 181.028 39.6066L127.995 92.6396L74.9619 39.6066C65.1988 29.8435 49.3697 29.8435 39.6066 39.6066C29.8435 49.3697 29.8435 65.1988 39.6066 74.9619L92.6396 127.995L39.6066 181.028C29.8435 190.791 29.8435 206.62 39.6066 216.383C49.3697 226.146 65.1988 226.146 74.9619 216.383L127.995 163.35L181.028 216.383C190.791 226.146 206.62 226.146 216.383 216.383C226.146 206.62 226.146 190.791 216.383 181.028L163.35 127.995L216.383 74.9619C226.146 65.1988 226.146 49.3697 216.383 39.6066Z"
    />
    <path
      fill="currentColor"
      d="M212.848 43.1421C205.037 35.3316 192.374 35.3316 184.563 43.1421L127.995 99.7107L71.4264 43.1421C63.6159 35.3317 50.9526 35.3317 43.1421 43.1421C35.3316 50.9526 35.3316 63.6159 43.1421 71.4264L99.7106 127.995L43.1421 184.563C35.3316 192.374 35.3316 205.037 43.1421 212.848C50.9526 220.658 63.6159 220.658 71.4264 212.848L127.995 156.279L184.563 212.848C192.374 220.658 205.037 220.658 212.848 212.848C220.658 205.037 220.658 192.374 212.848 184.563L156.279 127.995L212.848 71.4264C220.658 63.6159 220.658 50.9526 212.848 43.1421Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;

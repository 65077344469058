export default {
  accentYellow: {
    400: "#F7C518",
  },

  accentBlue: {
    400: "#006EF5",
  },

  maxWidth: "768px",

  padding: {
    outer: "0.75rem",
  },

  gap: {
    section: {
      300: "1rem",
      400: "1.5rem",
      500: "2rem",
    },
  },

  stroke: {
    300: "1px",
    400: "2px",
    500: "3px",
    600: "4px",
  },

  elevation: {
    300: "1px",
    400: "2px",
    500: "3px",
  },

  radius: {
    200: "0.25rem",
    300: "0.5rem",
    400: "0.75rem",
    500: "1rem",
    600: "1.25rem",
    700: "1.5rem",
  },

  black: {
    700: "rgb(21, 21, 21)",
    600: "rgb(40, 40, 40)",
    500: "rgb(70, 70, 70)",
    400: "rgb(95, 95, 95)",
  },

  white: {
    700: "rgb(255, 255, 255)",
    600: "rgb(233, 233, 233)",
    500: "rgb(215, 215, 215)",
    400: "rgb(190, 190, 190)",
  },

  red: {
    100: `rgb(255, 223, 224)`,
    200: `rgb(245, 164, 164)`,
    300: `rgb(255, 120, 120)`,
    400: `rgb(255, 75, 75)`,
    500: `rgb(234, 43, 43)`,
  },

  yellow: {
    200: `rgb(255, 245, 211)`,
    300: `rgb(251, 229, 109)`,
    400: `rgb(255, 200, 0)`,
  },

  orange: {
    400: `rgb(255, 177, 0)`,
    500: `rgb(255, 140, 0)`,
  },

  brown: {
    200: `rgb(229, 162, 89)`,
    300: `rgb(218, 139, 99)`,
    400: `rgb(165, 102, 68)`,
    500: `rgb(116, 66, 41)`,
  },

  green: {
    300: `rgb(0, 230, 118)`,
    400: `rgb(39, 222, 68)`,
    500: `rgb(0, 200, 83)`,
  },

  blue: {
    100: `rgb(221, 244, 255)`,
    200: `rgb(187, 242, 255)`,
    300: `rgb(132, 216, 255)`,
    400: `rgb(28, 176, 246)`,
    500: `rgb(24, 153, 214)`,
    600: `rgb(43, 112, 201)`,
    700: `rgb(20, 83, 163)`,
  },

  pink: {
    400: `rgb(255, 170, 222)`,
  },

  purple: {
    300: `rgb(206, 130, 255)`,
    400: `rgb(144, 105, 205)`,
    500: `rgb(111, 78, 161)`,
  },
};

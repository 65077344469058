import { useRef } from "react";
import styled from "styled-components";

export default () => {
  const canvasRef = useRef();

  return (
    <Wrapper className="webgl-wrapper">
      <Canvas className="webgl-canvas" ref={canvasRef} />
    </Wrapper>
  );
};

const Wrapper = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  user-select: none;
  contain: strict;
  overflow: clip;
  inset: 0;
  z-index: 0;
`;

const Canvas = styled.canvas`
  outline: none;
`;

import { useState, useEffect } from "react";
import { parseCookies, setCookie, removeCookie } from "../../libraries/cookies";

export const useCookie = (key, initialValue = "") => {
  const getCookie = () => {
    const parsedCookies = parseCookies(document.cookie);
    const cookieValue = parsedCookies[`gamly-${key}`];
    return cookieValue !== undefined ? cookieValue : initialValue;
  };

  const [cookie, setCookieState] = useState(getCookie);

  const set = (value, options = {}) => {
    setCookie(`gamly-${key}`, value, options);
    setCookieState(value);
  };

  const remove = (options = {}) => {
    removeCookie(`gamly-${key}`, options);
    setCookieState(undefined);
  };

  useEffect(() => {
    setCookieState(getCookie());
  }, []);

  return [cookie, set, remove];
};

import styled from "styled-components";

export default ({ size = "lg", children, ...rest }) => {
  const Tag = (() => {
    switch (size) {
      case "lg":
        return "h3";
      case "md":
        return "h4";
      case "sm":
        return "h5";
      case "xs":
        return "h6";
      default:
        return "h6";
    }
  })();

  return (
    <Headline $size={size} {...rest} as={Tag}>
      {children}
    </Headline>
  );
};

const getFontSize = (size) => {
  switch (size) {
    case "lg":
      return "1.75rem";
    case "md":
      return "1.5rem";
    case "sm":
      return "1.25rem";
    case "xs":
      return "1.125rem";
  }
};

const getLineHeight = (size) => {
  switch (size) {
    case "lg":
      return "2rem";
    case "md":
      return "2rem";
    case "sm":
      return "1.5rem";
    case "xs":
      return "1.375rem";
  }
};

const Headline = styled.h6`
  font-size: ${(p) => getFontSize(p.$size)};
  line-height: ${(p) => getLineHeight(p.$size)};
  font-weight: 700;
  letter-spacing: 0;
`;

export default {
  background: "rgb(30, 30, 30)",

  text: {
    500: "rgb(164, 164, 158)",
    400: "rgb(242, 242, 233)",
  },

  grayscale: {
    100: "rgb(30, 30, 30)",
    200: "rgb(47, 47, 47)",
    300: "rgb(75, 75, 75)",
    400: "rgb(102, 102, 102)",
    500: "rgb(140, 140, 140)",
    600: "rgb(185, 185, 182)",
    700: "rgb(210, 210, 205)",
    800: "rgb(226, 226, 220)",
    900: "rgb(242, 242, 233)",
  },
};

import { useEffect, useState } from "react";
import { useInterfaceStore } from "../stores/useInterfaceStore";

export const useThemeMode = () => {
  const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const isDarkMode = useInterfaceStore((s) => s.isDarkMode);
  const [mode, setMode] = useState("off");

  useEffect(() => {
    document.body.classList.remove("dark", "light");
    document.body.classList.add(isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  useEffect(() => {
    const localStorageValue = localStorage.getItem("gamly-theme");
    if (localStorageValue) {
      setMode(localStorageValue === "dark" ? "on" : "off");
      useInterfaceStore.setState({ isDarkMode: localStorageValue === "dark" });
      return;
    }

    setMode("systemStandard");
    useInterfaceStore.setState({ isDarkMode: prefersDarkScheme });
  }, []);

  const setTheme = (theme) => {
    if (theme === null) {
      setMode("systemStandard");
      localStorage.removeItem("gamly-theme");
      useInterfaceStore.setState({ isDarkMode: prefersDarkScheme });
      return;
    }
    setMode(theme === "dark" ? "on" : "off");
    useInterfaceStore.setState({ isDarkMode: theme === "dark" });
    localStorage.setItem("gamly-theme", theme);
  };

  return { isDarkMode, setTheme, mode };
};

import { useUserStore } from "../../stores/useUserStore";
import { isJWT } from "../../../libraries/validators/index";

export const login = async ({ token, setToken, controller }) => {
  const clearToken = () => {
    setToken("");
    useUserStore.getState().reset();
  };

  try {
    if (!token || !isJWT(token)) {
      clearToken();
      return;
    }

    const res = await fetch(`${import.meta.env.VITE_SERVER}/auth/login`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await res.json();
    if (controller.signal.aborted) return;

    if (!res.ok) throw new Error(data.error || "Unknown error occurred");

    if (!data.isError) {
      if (!useUserStore.getState().tag) useUserStore.setState(data);
      if (data.token)
        setToken(data.token, { expires: 365, secure: window.location.protocol === "https:", sameSite: "Lax" });
    } else {
      console.error(data);
      if (data.clientAction === "logout") clearToken();
    }

    return data;
  } catch (error) {
    console.error(error);
    return { error: error.message || error.error || "Unknown error occurred" };
  }
};

import styled from "styled-components";
import IconWrapper from "./IconWrapper";

export default ({ size = "md", stroke = 400, children, shadow, ...rest }) => {
  return (
    <Wrapper $shadow={shadow} {...rest}>
      <IconWrapper size={size} stroke={stroke}>
        {children}
      </IconWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0.25rem;
  cursor: pointer;

  ${(p) => p.$shadow && `filter: drop-shadow(${p.theme.shadow});`}
`;

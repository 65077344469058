import styled from "styled-components";

export default (props) => {
  return <Input {...props} autoComplete="off" />;
};

const Input = styled.input`
  border: ${(p) => p.theme.stroke[400]} solid ${(p) => p.theme.grayscale[200]};
  background: transparent;
  border-radius: ${(p) => p.theme.radius[400]};
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  outline: none;

  &:focus {
    border-color: ${(p) => p.theme.accentBlue[400]};
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const signUp = async ({ name }) => {
  try {
    const res = await fetch(`${import.meta.env.VITE_SERVER}/auth/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    });

    const data = await res.json();

    if (!res.ok) throw new Error(data.error || "Unknown error occurred");

    return data;
  } catch (error) {
    console.error(error);
    return { error: error.message || error.error || "Unknown error occurred" };
  }
};

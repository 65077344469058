import Loading from "./Loading";
import styled, { ThemeProvider, useTheme } from "styled-components";
import themeDark from "../styles/variables/dark";
import themeLight from "../styles/variables/light";

//TBD: rename hoverBrightness to hoverBackground and hoverColor

export default ({
  children,
  variant = "accentBlue",
  size = "md",
  loading = false,
  disabled = false,
  shape = "round",
  ...rest
}) => {
  const theme = useTheme();

  const variants = {
    yellow: {
      background: theme.accentYellow[400],
      color: themeLight.text[400],
      hoverBrightness: 1.1,
    },
    accentBlue: {
      background: theme.accentBlue[400],
      color: themeDark.text[400],
      hoverBrightness: 1.1,
    },
    theme: {
      background: theme.background,
      color: theme.text[400],
      hoverBrightness: 1.1,
    },
    dark: {
      background: themeDark.background,
      color: themeDark.text[400],
      hoverBrightness: 1.1,
    },
    light: {
      background: themeLight.background,
      color: themeLight.text[400],
      hoverBrightness: 0.925,
    },
    yellow: {
      background: theme.yellow[400],
      color: themeLight.text[400],
      hoverBrightness: 1.1,
    },
    red: {
      background: theme.red[400],
      color: theme.white[700],
      hoverBrightness: 1.25,
    },
    green: {
      background: theme.green[400],
      color: theme.white[700],
      hoverBrightness: 1.1,
    },
    game: {
      background: theme.primary ? theme.primary[400] : theme.light,
      color: theme.text[400],
      hoverBrightness: 1.1,
    },
  };

  return (
    <ThemeProvider theme={variants[variant]}>
      <Button $size={size} disabled={disabled} $shape={shape} {...rest}>
        {loading ? <Loading size="xs" /> : children}
      </Button>
    </ThemeProvider>
  );
};

const getHeight = (size) => {
  switch (size) {
    case "sm":
      return "2rem";
    case "md":
      return "2.5rem";
    case "lg":
      return "3rem";
  }
};

const getFontSize = (size) => {
  switch (size) {
    case "lg":
      return "1.125rem";
    case "sm":
      return "0.875rem";
    default:
      return "1rem";
  }
};

const getPadding = (size) => {
  switch (size) {
    case "sm":
      return "1rem";
    case "md":
      return "1.5rem";
    case "lg":
      return "2rem";
  }
};

const Button = styled.button`
  transition:
    background-color 0.1s ease-in-out,
    color 0.1s ease-in-out;
  border-radius: ${(p) => (p.$shape === "square" ? p.theme.radius[400] : "5rem")};
  background-color: ${(p) => p.theme.background};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 0 ${(p) => getPadding(p.$size)};
  height: ${(p) => getHeight(p.$size)};
  color: ${(p) => p.theme.color};
  font-weight: 700;

  font-size: ${(p) => getFontSize(p.$size)};

  ${(p) => p.$shine && "overflow: hidden;"}
`;

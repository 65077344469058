import EventEmitter from "../../libraries/EventEmitter";
import gsap from "gsap";

class Time extends EventEmitter {
  init() {
    if (this.initialized) return;
    this.initialized = true;

    this.start = Date.now();
    this.current = this.start;
    this.elapsed = 0;
    this.delta = 16;

    gsap.ticker.add(this.tick.bind(this));
    this.tickGSAPWhileHidden(true);
  }

  destroy() {
    gsap.ticker.remove(this.tick.bind(this));
    this.tickGSAPWhileHidden(false);
  }

  tick() {
    const currentTime = Date.now();
    this.delta = currentTime - this.current;
    this.current = currentTime;
    this.elapsed = this.current - this.start;

    this.emit("tick");
  }

  tickGSAPWhileHidden(value) {
    if (value === false) {
      document.removeEventListener("visibilitychange", this.tickGSAPWhileHidden.fn);
      return clearInterval(this.tickGSAPWhileHidden.id);
    }
    const onChange = () => {
      clearInterval(this.tickGSAPWhileHidden.id);
      if (document.hidden) {
        gsap.ticker.lagSmoothing(0); // keep the time moving forward (don't adjust for lag)
        this.tickGSAPWhileHidden.id = setInterval(gsap.ticker.tick, 500);
      } else {
        gsap.ticker.lagSmoothing(500, 33); // restore lag smoothing
      }
    };
    document.addEventListener("visibilitychange", onChange);
    this.tickGSAPWhileHidden.fn = onChange;
    onChange(); // in case the document is currently hidden.
  }
}

export default new Time();

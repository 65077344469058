export const isJWT = (token = "") => {
  const parts = token.split(".");

  if (parts.length !== 3) return false;

  try {
    // Base64Url decode the header and payload (ignoring signature for now)
    const header = JSON.parse(atob(parts[0].replace(/-/g, "+").replace(/_/g, "/")));
    const payload = JSON.parse(atob(parts[1].replace(/-/g, "+").replace(/_/g, "/")));

    // Optionally, you can further check the structure of the header and payload
    if (typeof header === "object" && typeof payload === "object") return true;
  } catch (e) {
    return false; // If decoding or parsing fails, it's not a valid JWT
  }

  return false;
};

import fonts from "./fonts";
import reset from "./reset";
import themeLight from "../variables/light";
import themeDark from "../variables/dark";
import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  ${fonts}
  ${reset}

  :root {
    --100vh: 100vh;
  }

  html {
    font: 16px;
    line-height: 18px;
    font-family: "Din-Round";
  }


  body.light {
    background-color: ${themeLight.background};
    color: ${themeLight.text[400]};
  }

  body.dark {
    background-color: ${themeDark.background};
    color: ${themeDark.text[400]};
  }
`;

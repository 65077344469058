import { CSSTransition } from "react-transition-group";
import { useRef } from "react";
import styled from "styled-components";

const TRANSITION = {
  NAME: "loading",
};

export default ({ size = "md", visible = true, enterDuration = 200, exitDuration = 200, ...rest }) => {
  const wrapperRef = useRef(null);

  return (
    <CSSTransition
      in={visible}
      timeout={{
        enter: enterDuration,
        exit: exitDuration,
      }}
      classNames={TRANSITION.NAME}
      unmountOnExit
      $enterDuration={enterDuration}
      $exitDuration={exitDuration}
      nodeRef={wrapperRef}>
      <Wrapper ref={wrapperRef} $size={size}{...rest}>
        <Dot $size={size} />
        <Dot $size={size} style={{ animationDelay: "0.25s" }} />
        <Dot $size={size} style={{ animationDelay: "0.5s" }} />
      </Wrapper>
    </CSSTransition>
  );
};

const Wrapper = styled.div`
  display: flex;
  transition: opacity 0.2s;
  gap: ${(p) =>
    p.$size === "xs"
      ? "0.25rem"
      : p.$size === "sm"
        ? "0.35rem"
        : p.$size === "md"
          ? "0.5rem"
          : p.$size === "lg"
            ? "0.6rem"
            : "1rem"};

  &.${TRANSITION.NAME}-enter {
    opacity: 0;
  }

  &.${TRANSITION.NAME}-enter-active {
    opacity: 1;
    transition: opacity ${(p) => p.$enterDuration / 1000}ms;
  }

  &.${TRANSITION.NAME}-exit {
    opacity: 1;
  }

  &.${TRANSITION.NAME}-exit-active {
    opacity: 0;
    transition: opacity ${(p) => p.$exitDuration / 1000}ms;
  }
`;

const Dot = styled.div`
  background: currentColor;
  border-radius: 50%;
  animation: scale 1s infinite alternate;
  aspect-ratio: 1/1;

  width: ${(p) =>
    p.$size === "xs"
      ? "0.8rem"
      : p.$size === "sm"
        ? "1rem"
        : p.$size === "md"
          ? "1.25rem"
          : p.$size === "lg"
            ? "1.5rem"
            : "1.75rem"};

  @keyframes scale {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0.5;
      transform: scale(0.5);
    }
  }
`;

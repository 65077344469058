export default {
  background: "rgb(255, 255, 255)",

  text: {
    500: "rgb(150, 150, 150)",
    400: "rgb(26, 26, 26)",
  },

  grayscale: {
    900: "rgb(26, 26, 26)",
    800: "rgb(44, 44, 44)",
    700: "rgb(72, 72, 72)",
    600: "rgb(97, 97, 97)",
    500: "rgb(140, 140, 140)",
    400: "rgb(190, 190, 190)",
    300: "rgb(215, 215, 215)",
    200: "rgb(233, 233, 233)",
    100: "rgb(255, 255, 255)",
  },
};

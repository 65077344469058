import EventEmitter from "../../libraries/EventEmitter";

const breakpoints = {
  xs: 480,
  sm: 768,
  md: 1024,
  lg: 1440,
};

const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

class Sizes extends EventEmitter {
  width = 0;
  height = 0;
  pixelRatio = 0;
  visible = true;
  breakpoint = "lg";
  aspect = 0;

  init() {
    this.updateSizes();
    window.addEventListener("resize", this.debouncedUpdateSizes.bind(this));
    window.addEventListener("visibilitychange", this.handleVisibilityChange.bind(this));
  }

  destroy() {
    window.removeEventListener("resize", this.updateSizes);
    window.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }

  handleVisibilityChange() {
    this.visible = document.visibilityState === "visible";
    this.emit(this.visible ? "visible" : "hidden");
  }

  debouncedUpdateSizes = debounce(() => this.updateSizes(), 150);

  updateSizes() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.aspect = this.width / this.height;
    this.pixelRatio = Math.min(window.devicePixelRatio, 2);

    // Update the --100vh custom property for CSS
    document.documentElement.style.setProperty("--100vh", `${this.height}px`);

    // Determine and update the current breakpoint
    const newBreakpoint = this.getBreakpoint(this.width);
    if (newBreakpoint !== this.breakpoint) {
      this.breakpoint = newBreakpoint;
      this.emit("breakpoint", this.breakpoint);
    }

    this.emit("resize");
  }

  getBreakpoint(width) {
    if (width < breakpoints.xs) return "xs";
    if (width < breakpoints.sm) return "sm";
    if (width < breakpoints.md) return "md";
    return "lg";
  }

  breakpointAtLeast(name) {
    return Object.keys(breakpoints).indexOf(this.breakpoint) >= Object.keys(breakpoints).indexOf(name);
  }
}

export default new Sizes();

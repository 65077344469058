export const onAfterRenderClient = () => {
  return;
  if ("serviceWorker" in navigator) {
    const workerRegistered = navigator.serviceWorker.controller;
    if (workerRegistered) return;
    navigator.serviceWorker
      .register("/_service-worker.js")
      .then((registration) => {
        console.log("Service Worker registered with scope: ", registration.scope);
        console.log(registration);
      })
      .catch((error) => {
        console.log("Service Worker registration failed:", error);
      });
  }
};

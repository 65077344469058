import { useEffect, lazy, Suspense } from "react";
import WebGLCanvas from "../components/webgl/WebGLCanvas";
import time from "../utils/time";
import sizes from "../utils/sizes";
import resources from "../utils/resources";
import SignUpModal from "../components/SignUpModal";
import { useCookie } from "../composables/useCookie";
import { useUserStore } from "../stores/useUserStore";
import { useTouch } from "../composables/useTouch";
import { ThemeProvider } from "styled-components";
import themeBase from "../styles/variables/base.js";
import themeLight from "../styles/variables/light.js";
import themeDark from "../styles/variables/dark.js";
import { useThemeMode } from "../composables/useThemeMode.js";
import GlobalStyles from "../styles/global/index.js";
import { login } from "../services/auth/login.js";
import { useInterfaceStore } from "../stores/useInterfaceStore.js";

const ModalError = lazy(() => import("../components/ModalError"));

//TBD: lazy signup modal

export default ({ children }) => {

  useTouch();
  const { isDarkMode } = useThemeMode();
  const tag = useUserStore((s) => s.tag);
  const error = useInterfaceStore((s) => s.error);
  const [token, setToken] = useCookie("token");

  useEffect(() => {
    time.init();
    sizes.init();
    resources.startLoading();

    const controller = new AbortController();
    if (token) login({ token, setToken, controller });

    return () => {
      time.destroy();
      sizes.destroy();
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const visible = token && !tag;
    const ref = document.querySelector(".splash");
    if (visible) {
      ref.classList.remove("splash-hidden");
    } else {
      ref.classList.add("splash-hidden");
    }
  }, [token, tag]);

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={{ ...themeBase, ...(isDarkMode ? themeDark : themeLight) }}>
        <WebGLCanvas />
        {(token && tag) || !token ? children : null}
        <SignUpModal />
        <Suspense>{error?.visible === true ? <ModalError /> : null}</Suspense>
      </ThemeProvider>
    </>
  );
};
